/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 03-mar-2020, 16:50:42
    Author     : jose.ochoa
*/

body
{
    background-color: #FFF;

}
.BarHeader
{
    background: #F1F1F1 0% 0% no-repeat padding-box;
    align-content: center;
    height: 70px;
    width: 100%;
}
.BarHeader .content
{
    width: 70%;
    min-width: 944;
    margin: auto !important; 
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
    background: url("../resources/logoGSE.png");
    background-position: right;
    background-repeat: no-repeat;
}

.Header img
{
   width: 100%;
   height: 180px;
   
}


.contentPage
{
    width: 100%;
   background: #fFF url("../resources/bannerfirmaya3.png"); 
   background-size: cover;
   background-repeat: no-repeat;
    background-position: 0% 00%;
   
}

.mainTitle
{
    height: 120px;
    background: #000000 0% 0% no-repeat padding-box;
    mix-blend-mode: normal;
    opacity: 0.3;
    

}
.textTitle
{
    position: absolute;
    top: 75px;
    left: 15%;
    height: 41px;

    width: 70%;
    margin: auto !important;     
    color: #FFFFFF  !important;    
    font-size: 33px;
    padding-top: 10px;
    
    box-sizing: border-box;
}
.contentView
{
    width: 100%;
    color: #a49b99;
    font-size: 1rem;
    padding: 15px;
}
.contentHeadText
{
    width: 70%;
    min-width: 500px;
    margin: auto !important; 
}

.contentViewHeader
{
    width: 70%;
    margin: auto !important; 
    color: #a49b99;
    font-size: 1rem;
    padding: 20px;
    box-sizing: border-box;
    background-color: #FFF;
    border:1px solid #F5AE19;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    position: relative;
    top:-40px;
    min-height: 600px;
}
.contentView h3{
     color: #a49b99;
     font-size: 1.8rem;
}


.contentCapcha
{
    min-width:233;
    margin-bottom: 10px;;
}

#captcha
{
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    border:1px solid  #eeeeee;
    background-color: #FFF;;
}
.divContentText
{
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background-color: #eeeeee;
    padding: 20px;
    box-sizing: border-box;
}
.btnClass
{
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    color: #FFF;
    font-size: 18px;
    padding: 10px;   
    background-color: #F5AE19;
    border: #f5ae19 2px solid ;
}
.btnClass:hover
{
    background-color: #e46219;
}

.contentPDF
{
    align-content: center;
    width: 100%;
    min-width: 824px;
    margin: 20px;

}